import React from "react"
import { Link } from "gatsby"

import react from "../../images/react.png"
import node from "../../images/node.png"

import vite from "../../images/vite.png"
import tailwind from "../../images/tailwind.png"
import django from "../../images/django.png"
import d3 from "../../images/d3.png"
import github from "../../images/github.svg"
import boxArrow from "../../images/box-arrow-up-right.svg"

import spitballDemo1 from "../../images/spitball-demo.png"

const Spitball = () => {
  return (
    <div className="project-two">
      <div className="proj-left">
        {" "}
        <div className="title-card">
          <a href="https://spitballnba.netlify.app/" target="_blank">
            <h1>Spitball</h1>
          </a>

          <div className="app-links" id="socials">
            {" "}
            <a href="https://github.com/glumslug/spitball-full" target="_blank">
              <img src={github} alt="" />
            </a>
            <a href="https://spitballnba.netlify.app/" target="_blank">
              <img src={boxArrow} alt="" />
            </a>
          </div>
        </div>
        <div className="description">
          <p className="tagline"> NBA Stats Vizualizer</p>
          <p>
            An app built on swar's nba api, and using D3 to visualize statistics
            based on user selection.
          </p>
        </div>
        <div className="description stack">
          <ul>
            <li>
              <img src={react} alt="" />
            </li>
            <li>
              <img src={node} alt="" />
            </li>
            <li>
              <img src={django} alt="" />
            </li>
            <li>
              <img src={vite} alt="" />
            </li>
            <li>
              <img src={tailwind} alt="" />
            </li>
            <li>
              <img src={d3} alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div className="proj-right">
        <img src={spitballDemo1} alt="" />
      </div>
    </div>
  )
}

export default Spitball
